import * as React from "react";
import { useEffect } from "react";
import "./layout/styles.scss";
import {
  TitleSection,
  BlogBreadcrumbs,
  SEOMetaTags,
  CallToAction,
  GreyFooter,
  FundingBanner,
  PrevButton,
  NextButton
} from "../components";
import CategoryTabs from "../components/CategoryTabs/CategoryTabs";
import BlogPostCard from "../components/BlogPostCard/BlogPostCard";
import MainMenu from "../components/MainMenu/MainMenu";
import { slugify } from "../helpers/helpers";
import { IBlogTags } from "./interfaces";


const BlogTags = (data: IBlogTags): JSX.Element => {

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  let { hasNextPage, hasPrevPage, nextPage, prevPage, items, tag, seoMetaTags, menu, lang, footer, urlName, pageTitle, cta, c2c, banner, categoriesData } = data.pageContext;
  const prevLink = hasPrevPage &&
    <link rel="prev" href={`https://pushpushgo.com/${lang}/${urlName}/category/${slugify(tag)}/${prevPage}`}/>;
  const nextLink = hasNextPage &&
    <link rel="next" href={`https://pushpushgo.com/${lang}/${urlName}/category/${slugify(tag)}/${nextPage}`}/>;
  const canonicalLink = <link rel="canonical"
                              href={`https://pushpushgo.com/${lang}/${urlName}/category/${slugify(tag)}/1`}/>;

  const pageTitleData = pageTitle && pageTitle.find(el => el.page === "blog");
  const pageName = pageTitleData && pageTitleData.name;
  const description = pageTitleData && pageTitleData.description;
  const mainCategory = data && tag;
  const mainCategoryData = items && items[0].post.tags.find(el => el.name === mainCategory);

  const alternateCategoryData = mainCategoryData && mainCategoryData._allNameLocales.filter(el => el.locale !== lang);
  const alternateCategory = alternateCategoryData && (alternateCategoryData.length > 0) && alternateCategoryData[0].value;
  const alternateLocale = lang && (lang === "pl" ? "en" : "pl");
  const alternateLink = alternateCategory && `https://pushpushgo.com/${alternateLocale}/${urlName}/category/${slugify(alternateCategory)}/1/`;

  const alternateLinks = [{ locale: alternateLocale, link: alternateLink }];

  return (
    <>
      <MainMenu menu={menu} lang={lang} c2c={c2c}/>
      <SEOMetaTags tags={seoMetaTags}
                   lang={lang}
                   prevLink={prevLink}
                   nextLink={nextLink}
                   canonicalLink={canonicalLink}
                   pageName={pageName}
                   alternateLinks={alternateLinks}/>
      <TitleSection propertyReference={[{ key: "title", value: `${pageName}` }, {
        key: "blog",
        value: "blog-version",
      }, { key: "color", value: "green-version" }, { key: "paragraph", value: `${description}` }]}/>
      <section className="subpage-section">
        <div className="content-limiter">
          <BlogBreadcrumbs pages={[{ key: "page", value: `${pageName}`, url: `/${lang}/${urlName}/page/1` }]}/>
          {categoriesData && categoriesData.length > 0 &&
          <CategoryTabs items={categoriesData} url={`${lang}/${urlName}/category/`}/>}
          <div className="cards-grid">
            {items.map(post => <BlogPostCard key={Math.random() + new Date().toDateString()} {...post} lang={lang}/>)}
          </div>
          <nav>
            {hasPrevPage && <PrevButton lang={lang} path={`/${lang}/${urlName}/category/${slugify(tag)}/${prevPage}`}/>}
            {hasNextPage && <NextButton lang={lang} path={`/${lang}/${urlName}/category/${slugify(tag)}/${nextPage}`}/>}
          </nav>
        </div>
      </section>
      <CallToAction cta={cta}/>
      <GreyFooter footer={footer} lang={lang}/>
      <FundingBanner banner={banner}/>
    </>
  );
};

export default BlogTags;
